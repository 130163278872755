<template>
  <vs-card style="margin-bottom:3%">
    <div slot="header">
      <vs-row>
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b>{{ data.person_name }} - {{ data.level }}</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="info"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <div>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Talked about</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.engagement_details }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Last Contact</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ unixTimestampConvert(getRoleLastCall(data)) }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Next Call at</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ unixTimestampConvert(getRoleNextCall(data)) }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Source</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.source }} - {{data.source_details }}</vs-col
        >
      </vs-row>
      <vs-row>
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Company</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.company }}</vs-col
        >
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
// import EventBus from "../eventbus.js";
// import axios from "axios";
// import constants from "../../../constants.json";
// import moment from "moment";
export default {
  props: {
    data: {},
    type: {}
  },
  methods: {
    openPopUp() {
      this.openBigPopup(this.data.id);
    }
  }
};
</script>

<style></style>
