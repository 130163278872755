<template>
  <vs-card style="margin-bottom:3%">
    <div slot="header">
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b>{{ data.person_name }}</b>-<b>{{ data.level }}</b>
          <!-- <b v-if="getrole('cm1')">{{ data.level }}</b>
          <b v-if="getrole('exed') || getrole('exed2')">{{
            data.iiml_level
          }}</b> -->
          <!-- - <b>{{ data.company }}</b> - -->
          <!-- <b>{{ data.source }}</b> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="info"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <div>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="right"
          vs-align="flex-start"
          vs-w="3"
          >Escalated By:</vs-col
        >
        <vs-col
          v-if="tab === 'escalations'"
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          >{{ data.escalated_by_name }}</vs-col
        >
        <vs-col
          v-else
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          >{{ data.sv_added_by_name }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="right"
          vs-align="flex-start"
          vs-w="3"
          >Comments:</vs-col
        >
        <vs-col
          v-if="tab === 'escalations'"
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          >{{ data.escalated_comments }}</vs-col
        >
        <vs-col
          v-else
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          >{{ data.sv_comments }}</vs-col
        >
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
// import EventBus from "../eventbus.js";
// import axios from "axios";
// import constants from "../../../constants.json";
// import moment from "moment";
export default {
  props: {
    data: {},
    type: {},
    tab: {
      type: String,
      required: false,
      default: "escalations",
    },
  },
  mounted() {
    console.log(this.data);
  },
  methods: {
    async openPopUp() {
      this.openBigPopup(this.data.mwb_id);
      // if(this.data.level == 'M7' && this.data.city == 'Hyderabad'){
      //   if(this.data.courses.indexOf('CPA') != -1){
      //     this.openCPAPopup(this.data);
      //     return;
      //   }else if(this.data.courses.indexOf('CMA') != -1){
      //     this.openCMAPopup(this.data);
      //     return;
      //   }
      // }
      // this.openMWBPopup(this.data);
    },
    // unixTimestampConvert(value) {
    //   var dateString = moment.unix(value).format("DD-MMMM-YYYY");
    //   switch (dateString) {
    //     case "01/01/1970":
    //       return "";
    //     default:
    //       return dateString;
    //   }
    // }
  },
};
</script>

<style></style>
