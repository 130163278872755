<template>
  <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
    <vx-card class="p-2">
        <div class="text-center">
            <h4>{{ data.title }}</h4>
        </div>
        <div class="mb-4 mt-base">
            <div class="flex justify-between">
                <small class="font-semibold">16</small>
                <small class="text-grey">20</small>
            </div>
            <vs-progress :color="'primary'" :percent="data.percent" class="block mt-1 shadow-md"></vs-progress>
        </div>
        <div class="flex justify-between flex-wrap">
        </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  props: {
    data: { }
  }

}
</script>

<style>

</style>
